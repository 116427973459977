<template>
  <div class="page-container">
    <AppChildPageHeader
      :title="pageTitle"
      :breadcrumbs="breadcrumbs"
    ></AppChildPageHeader>

    <v-card class="page-card-wrapper">
      <v-row no-gutters justify="center">
        <v-col cols="12" md="8">
          <FormLayout1 :noLabel="true">
            <v-card-title class="px-0 mb-2 text-capitalize font-weight-regular">
              <v-icon class="mr-2">mdi-file-document-multiple-outline</v-icon>
              {{ $t('text.basicInformation') }}
            </v-card-title>
          </FormLayout1>
          <v-form ref="contactApplicationDetailsForm">
            <template v-for="(item, index) in formFields">
              <FormLayout1
                :key="index"
                :label="$t(`text.${item.label}`)"
                class="pt-3"
              >
                <AppFormField
                  ref="formField"
                  v-model="item.model"
                  :outlined="true"
                  :dense="true"
                  :type="item.type"
                  :items="item.items"
                  :readonly="true"
                ></AppFormField>
              </FormLayout1>
            </template>
          </v-form>
        </v-col>
      </v-row>

      <v-divider class="my-8"></v-divider>

      <v-row no-gutters justify="center">
        <v-col cols="12" md="8">
          <template v-for="(i, index) in dataFields">
            <FormLayout1 :key="index" :label="$t(`text.${i}`)" class="mb-4">
              <div class="text-body-1">{{ contactApplicationObj[i] }}</div>
            </FormLayout1>
          </template>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import commonMixin from '@/mixins/common.mixin'
import crudMixin from '@/mixins/crud.mixin'
import FormLayout1 from '@/components/form/FormLayout-1'
import { ROUTE_NAME, FORM_FIELD_TYPE } from '@/constants'
import { i18nHelper } from '@/utils'
import {
  CONTACT_APPLICATION_GET_CONTACT_APPLICATION,
  CONTACT_APPLICATION_INITIAL_GET_CONTACT_APPLICATION_STATE
} from '@/store/contact-application.module'

export default {
  name: 'ContactApplicationDetails',
  components: {
    FormLayout1
  },
  mixins: [commonMixin, crudMixin],
  data: () => ({
    id: null,
    pageTitle: 'contactApplicationDetails',
    breadcrumbs: [
      {
        text: 'home',
        disabled: false,
        to: { name: ROUTE_NAME.HOME }
      },
      {
        text: 'contactApplication',
        disabled: false,
        to: { name: ROUTE_NAME.CONTACT_APPLICATION_LIST }
      },
      {
        text: 'details',
        disabled: true
      }
    ],
    contactApplicationObj: {},
    formFields: [
      {
        model: '',
        label: 'name',
        key: 'name',
        lg: 6,
        cols: 12
      },
      {
        model: '',
        label: 'phoneNumber',
        key: 'phoneNumber',
        lg: 6,
        cols: 12
      },
      {
        model: '',
        label: 'email',
        key: 'email',
        lg: 6,
        cols: 12
      },
      {
        model: '',
        label: 'description',
        key: 'description',
        lg: 6,
        cols: 12,
        type: FORM_FIELD_TYPE.TEXTAREA
      }
    ],
    dataFields: ['createdAt'],
    actions: {
      readRecord: {
        module: 'contactApplication',
        state: 'contactApplication',
        action: CONTACT_APPLICATION_GET_CONTACT_APPLICATION,
        title: i18nHelper.getMessage('text.getContactApplicationDetails'),
        successAction: () => {}
      },
      initialReadRecordState:
        CONTACT_APPLICATION_INITIAL_GET_CONTACT_APPLICATION_STATE
    }
  }),
  created() {
    this.initCategoryDetails()
  },
  methods: {
    importContactApplicationData(response) {
      let data = response.data

      this.formFields.forEach((x) => {
        this.formFields.find((y) => y.key == x.key).model = data[x.key]
      })
      this.contactApplicationObj = data
    },
    importSuccessAction() {
      this.actions.readRecord.successAction = (response) => {
        this.importContactApplicationData(response)
      }
    },
    initCategoryDetails() {
      let id = this.$route.params.id

      this.id = id
      this.readRecord(id)
      this.importSuccessAction()
    }
  }
}
</script>

<style></style>
